<template>
    <div class="container" style="margin-top: 80px;">
        <div class="row" v-if="manufacturer.logolink != null">
            <div class="col-12 text-center">
                <img v-if="manufacturer.logolink != null" :src="manufacturer.logolink" style="max-height: 60px;" >
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-7">
                <h1 v-if="manufacturer.id == null">Hersteller initialisieren</h1>
                <p v-if="manufacturer.id == null">Bitte den QR-Code des Herstellers scannen</p>
                <h1 v-if="manufacturer.id != null && visitor.id == null">Herzlich Willkommen</h1>
                <p  v-if="manufacturer.id != null && visitor.id == null">
                    Bitte klicken Sie rechts auf den Button "Hier einloggen" und scannen Sie anschließend Ihren QR-Code auf Ihrem Besucherpass.
                </p>
                <h1 v-if="manufacturer.id != null && visitor.id != null">Eingeloggt: {{ visitor.name }}</h1>
                <p v-if="manufacturer.id != null && visitor.id != null && redeemed == false">
                    <span v-if="visitor.tickets.length == 0">Sie haben bereits alle möglichen Gewinnlose gelöst. Wir wünschen viel Glück!</span>
                    <span v-if="visitor.tickets.length > 0">Sie können noch {{ visitor.tickets.length }} Gewinnlose lösen. Klicken Sie auf "Los lösen" um das Gewinnlos einzulösen</span>
                </p>
                <p v-if="redeemed">Ticket an diesem Stand bereits gelöst</p>
                <div v-if="visitor.tickets.length > 0 && redeemed == false">
                    <h3>Offene Gewinnlose</h3>
                    <p>Folgende Gewinnlose können Sie bei weiteren Ständen noch einlösen.</p>
                    <ul>
                        <li style="display: inline-block; width: 48%; font-size: 1.5rem;" v-for="ticket in visitor.tickets">
                            {{ ticket.name }}
                        </li>
                    </ul>
                </div>
               
            </div>
            <div class="col-md-5 mt-3">
                <qrcode-stream v-if="scanQr" @decode="onDecode" @init="onInit" class="mb-3"></qrcode-stream>
                <a class="btn text-light btn-lg btn-primary btn-block" v-if="manufacturer.id == null" @click="scanQr = true">Hersteller initialisieren</a>
                <a class="btn text-light btn-lg btn-primary btn-block" v-if="manufacturer.id != null && visitor.id == null" @click="scanQr = true">Hier einloggen</a>
                <a class="btn text-light btn-lg btn-primary btn-block" v-if="manufacturer.id != null && visitor.id != null && visitor.tickets.length > 0 && redeemed == false" @click="redeem()">Los lösen</a>
                <a class="btn text-light btn-lg btn-secondary btn-block" v-if="manufacturer.id != null && visitor.id != null && countDown == 0" @click="logout()">Ausloggen</a>
                <a class="btn text-light btn-lg btn-secondary btn-block" v-if="manufacturer.id != null && visitor.id != null && countDown != 0" @click="logout()">Auto-Logout in {{ countDown }} <span v-if="countDown != 1">Sekunden</span><span v-if="countDown == 1">Sekunde</span></a>

            </div>

        </div>
    
        <a style="width: 100px; height: 100px; position: absolute; bottom: 0; right: 0;" @click="reset()"></a>
    </div>
</template>

<script>

import axios from "axios";
import convert from "xml-js";
import Swal from 'sweetalert2'


export default {
    data () {
        return {
            country: 'ch',
            manufacturer: {
                id: null,
                name: null,
                logolink: null,
                winId: null
            },
            visitor: {
                id: null,
                name: null,
                tickets: [

                ]
            },
            redeemed: false,
            result: '',
            error: '',
            scanQr: false,
            countDown : 0
        }
    },
    mounted () {
        console.log(localStorage.getItem('manufacturerWinId'))
        this.manufacturer.id = localStorage.getItem('manufacturerId')
        this.manufacturer.name = localStorage.getItem('manufacturerName')
        this.manufacturer.logolink = localStorage.getItem('manufacturerLogoLink')
        this.manufacturer.winId = localStorage.getItem('manufacturerWinId')
    },
    methods: {
        onDecode (decodedString) {
            if (this.manufacturer.id == null) {
                this.initManufacturer(decodedString)
                this.scanQr = false
            }
            else if(this.visitor.id == null) {
                this.initVisitor(decodedString)
                this.scanQr = false
            }
            //this.result = decodedString
        },
        logout() {
            this.visitor = {
                id: null,
                name: null,
                tickets: [

                ]
            }
            this.redeemed = false
            this.countDown = 0
        },
        reset() {
            localStorage.removeItem('manufacturerId')
            localStorage.removeItem('manufacturerName')
            localStorage.removeItem('manufacturerLogoLink')
            localStorage.removeItem('manufacturerWinId')

            Swal.fire({
                title: 'Manufacturer reset!',
                text: 'Please reload the page to initialize the manufacturer again',
                type: 'success',
                confirmButtonText: 'Ok'
            }).then((result) => {
                window.reload()
            })
        },
        countDownTimer() {
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
            else {
                this.logout()
            }
        },
        initVisitor(visitorId) {
            var that = this;

            var bodyFormData = new FormData();
            bodyFormData.set('id', 'b5c17871dfc2bc33f39da8f0d6dfc800');
            bodyFormData.set('App_id', this.manufacturer.id);
            bodyFormData.set('Besucher_id', visitorId);
            bodyFormData.set('action', 'login');


            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: 'https://www.imevents.' + this.country + '/imtop/publikumsmagnet/index.php',
                data: bodyFormData
            }).then(function (response) {

                var options = {ignoreComment: true, alwaysChildren: true, compact: true}
                var result = convert.xml2js(response.data, options)

                var besucher = result.MesseApp.Data.Besucher
                that.visitor.name = besucher.Name._text;
                that.visitor.id = besucher.Besucher_id._text;

                var alreadyRedeemed = true;

                for (var i = 0; i < besucher.Gewinnlose_offen.Los.length; i++) {
                    that.visitor.tickets.push({
                        name: besucher.Gewinnlose_offen.Los[i].Losname._text,
                        winid: besucher.Gewinnlose_offen.Los[i].Losnummer._text
                    })

                    if(besucher.Gewinnlose_offen.Los[i].Losnummer._text == that.manufacturer.winId) {
                        alreadyRedeemed = false;
                    }
                }

                that.redeemed = alreadyRedeemed;
               
            }).catch((error) => {
                 Swal.fire({
                    title: 'Fehler!',
                    text: 'Beim Login ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut',
                    type: 'error',
                    confirmButtonText: 'Ok',
                    timer: 3500
                })

                that.logout()

            });;


        },
        initManufacturer(manufacturerId) {
            var that = this;

            var bodyFormData = new FormData();
            bodyFormData.set('id', 'b5c17871dfc2bc33f39da8f0d6dfc800');
            bodyFormData.set('App_id', manufacturerId);
            bodyFormData.set('action', 'init');

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: 'https://www.imevents.' + this.country + '/imtop/publikumsmagnet/index.php',
                data: bodyFormData
            }).then(function (response) {

                var options = {ignoreComment: true, alwaysChildren: true, compact: true}
                var result = convert.xml2js(response.data, options)

                var magnet = result.MesseApp.Data.Besuchermagnet

                that.manufacturer.name = magnet.Herstellername._text;
                that.manufacturer.logolink = magnet.Herstellerlogo._text;
                that.manufacturer.winId = magnet.Losnummer._text;
                that.manufacturer.id = manufacturerId

                localStorage.setItem('manufacturerId', that.manufacturer.id)
                localStorage.setItem('manufacturerName', that.manufacturer.name)
                localStorage.setItem('manufacturerLogoLink', that.manufacturer.logolink)
                localStorage.setItem('manufacturerWinId', that.manufacturer.winId)

            }).catch((error) => {
                 Swal.fire({
                    title: 'Fehler!',
                    text: 'Beim Initialisieren des Herstellers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut',
                    type: 'error',
                    confirmButtonText: 'Ok',
                    timer: 3500
                })
            });;
            
           

        },
        redeem() {
            var that = this;

            var bodyFormData = new FormData();
            bodyFormData.set('id', 'b5c17871dfc2bc33f39da8f0d6dfc800');
            bodyFormData.set('App_id', this.manufacturer.id);
            bodyFormData.set('Besucher_id', this.visitor.id);
            bodyFormData.set('action', 'redeem');

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: 'https://www.imevents.' + this.country + '/imtop/publikumsmagnet/index.php',
                data: bodyFormData
            }).then(function (response) {

                that.redeemed = true;

                Swal.fire({
                    title: 'Ticket gelöst!',
                    text: 'Das Ticket wurde erfolgreich gelöst',
                    type: 'success',
                    confirmButtonText: 'Ok',
                    timer: 1500
                })
                
                that.countDown = 30
                that.countDownTimer()

            }).catch((error) => {
                 Swal.fire({
                    title: 'Fehler!',
                    text: 'Beim Lösen des Tickets ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut',
                    type: 'error',
                    confirmButtonText: 'Ok',
                    timer: 3500
                })
            });
            


        },
        async onInit (promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                this.error = "ERROR: you need to grant camera access permisson"
                } else if (error.name === 'NotFoundError') {
                this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                this.error = "ERROR: Stream API is not supported in this browser"
                }
            }
        }
    }

}

</script>