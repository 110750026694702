import 'bootstrap'
import '../scss/app.scss';

import Vue from "vue";
import VueQrcodeReader from "vue-qrcode-reader";

Vue.use(VueQrcodeReader);

import ImTopComponent from './ImTopComponent.vue'

Vue.component('im-top', ImTopComponent)

new Vue({ el: '#app' })

